// 信息动态
import request from '../request'

export default class Infos {
    /**
*  信息详情 
* @param {*} params
* @returns
*/
    static shareDetail(params) {
        return request.get('/api/v1/newsrelease/shareDetail', {
            params
        })
    }
    /**
*  banner图
* @param {*} params
* @returns
*/
    static banner() {
        return request.get('/api/v1/newsrelease/banner')
    }
    /**
* 信息发布列表
* @param {*} params 
* @returns 
*/
    static share(params) {
        return request.get("/api/v1/newsrelease/share", {
            params
        })
    }
}